<template>
  <h1 class="text-2xl font-semibold">Order Summary</h1>
  <div class="flex justify-between font-semibold items-center text-sm">
    <p class="text-sm text-gray-500">Products</p>
  </div>
  <div
    :class="{ 'h-400': comboCart.length + container.length > 3 }"
    class="overflow-y-auto scrollbar scrollbar-thumb-rounded-full scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-200 scrollbar-track-rounded-full pb-4 pr-4"
  >
    <ContainerOrder
      v-for="(item, index) in container"
      :key="item"
      :container="item"
      :name="changeType"
      :position="index"
      @deleteContainer="deleteContainer"
    />
    <FoodOrder
      v-for="item in comboCart"
      :key="item"
      :name="changeType"
      :data="item"
      @updateCombo="updateOrderItem"
      @deleteOrderItem="deleteOrderItem"
    />
  </div>
  <div class="">
    <div class="grid grid-rows-1 py-4">
      <div class="w-full">
        <div class="text-sm">
          <div class="grid grid-cols-2">
            <div>Subtotal</div>
            <div class="text-right font-semibold">
              &#8358;{{ formatNumber(getCost) }}
            </div>
            <div class="mt-2">Shipping Cost</div>
            <div class="text-right font-semibold mt-2">
              &#8358;{{ formatNumber(shippingCost) }}
            </div>
            <div v-if="serviceCharge" class="mt-2">Service charge</div>
            <div v-if="serviceCharge" class="text-right font-semibold mt-2">
              &#8358;{{ formatNumber(serviceCharge) }}
            </div>
          </div>
          <div
            :class="{ 'border-b-2 ': Discount == 0 }"
            class="grid grid-cols-2 pb-2"
          >
            <div class="mt-2">Discount</div>
            <div class="text-right">
              <button
                v-if="Discount == 0"
                @click="toggleDiscount"
                class="text-brand text-sm text-right mt-2 font-semibold"
              >
                <span v-if="!showDiscount">Have a discount code?</span>
                <span v-else>Have no discount code?</span>
              </button>
              <div v-else class="text-right font-semibold mt-2">
                <span class="uppercase"> {{ discountCode }} </span>
              </div>
            </div>
          </div>
          <div :class="{ 'border-b-2 ': Discount > 0 }" class="pb-2 text-right">
            <a
              v-if="Discount != 0"
              @click="emptyDiscountCode"
              class="text-sm text-brand font-semibold"
              >{{ discountMessage }}</a
            >
          </div>
          <div v-show="showDiscount" class="col-span-2">
            <div class="flex space-x-3">
              <input
                class="input"
                v-model="discountCode"
                type="text"
                placeholder="Enter a discount code"
              />
              <button
                @click="useDiscount"
                :disabled="showdiscountPreloader"
                class="mt-2 px-3 bg-brand rounded font-semibold text-sm"
              >
                <Preloader v-if="showdiscountPreloader" />
                <span v-else>Apply</span>
              </button>
            </div>
            <p v-if="error" class="text-red-500 text-xs mt-1">{{ error }}</p>
          </div>
          <div
            :class="{ 'border-b-2 ': mealInstruction == 0 }"
            class="grid grid-cols-2 pb-2"
          >
            <div class="mt-2">Meal instruction</div>
            <div class="text-right">
              <button
                v-if="!mealInstruction"
                @click="toggleShowMealInstruction"
                class="text-brand text-sm text-right mt-2 font-semibold"
              >
                <span v-if="!showMealInstruction">Add Instruction?</span>
                <span v-else>Have no meal instruction?</span>
              </button>
            </div>
          </div>
          <div :class="{ 'border-b-2 ': Discount > 0 }" class="pb-2 text-right">
            <a
              v-if="mealInfoMessage"
              class="text-sm text-brand font-semibold"
              >{{ mealInfoMessage }}</a
            >
          </div>
          <div v-show="showMealInstruction" class="col-span-2">
            <div class="flex space-x-3">
              <input
                class="input"
                v-model="mealInstruction"
                type="text"
                placeholder="Enter meal instruction"
              />
              <button
                @click="addMealInfo"
                class="mt-2 px-3 bg-brand rounded font-semibold text-sm"
              >
                <span>Add</span>
              </button>
            </div>
            <!-- <p v-if="error" class="text-red-500 text-xs mt-1">{{ error }}</p> -->
          </div>
          <div v-if="Discount > 0" class="my-2 rounded bg-brand p-2">
            <p class="text-xs text-center">
              You will receive a cashback of &#8358;{{ formatNumber(Discount) }}
              into your wallet
            </p>
          </div>
          <div class="grid grid-cols-2">
            <div class="mt-2">Total</div>
            <div class="text-right font-semibold mt-2">
              &#8358;{{ formatNumber(getTotalCost.toFixed(2)) }}
            </div>
          </div>
          <div v-if="activeuser.walletBalance > 0" class="grid grid-cols-2">
            <div class="mt-2">Wallet Balance</div>
            <div class="text-right font-semibold mt-2">
              &#8358;{{
                activeuser.walletBalance
                  ? formatNumber(activeuser.walletBalance)
                  : 0
              }}
            </div>
          </div>
          <div
            class="grid grid-cols-2"
            v-if="getTotalCost > activeuser?.walletBalance ?? 0"
          >
            <div class="mt-2">Amount to Pay</div>
            <div class="text-right font-semibold mt-2">
              &#8358;{{
                formatNumber(
                  (
                    getTotalCost - Number(activeuser?.walletBalance ?? 0)
                  ).toFixed(2)
                )
              }}
            </div>
          </div>
          <div class="mt-2">
            Continue ordering?
            <router-link
              :to="`/restaurant/${
                order.restaurant.publicUrl ? order.restaurant.publicUrl : ''
              }`"
              class="text-brand font-semibold"
              >Back to Restaurant</router-link
            >
          </div>
          <div class="font-semibold text-xs text-gray-500 mt-2">
            Expect your meal on or before {{ order.cartDto.timeBelt.endTime }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FoodOrder from "@/components/FoodOrder.vue";
import ContainerOrder from "@/components/ContainerOrder.vue";
import Preloader from "@/components/Preloader.vue";

const tooltip = require("tooltip-plugin/dist/tooltip.min.js");
export default {
  name: ["OrderSummary"],
  props: [
    "showCart",
    "showCartBtn",
    "activeuser",
    "shippingCost",
    "getNewchanges",
  ],
  emits: ["cartChange", "updateOrderCheckout"],
  components: {
    FoodOrder,
    Preloader,
    ContainerOrder,
  },
  data() {
    return {
      totalAmount: 0.0,
      Discount: 0.0,
      discountCode: "",
      showMealInstruction: false,
      mealInstruction: "",
      showDiscount: false,
      discountMessage: "",
      mealInfoMessage:'',
      showdiscountPreloader: false,
      showPayPreloader: false,
      showPreloader: false,
      error: "",
      subTotalCost: 0.0,
      Cart: [],
      type: "orderSummary",
      order: this.$store.getters.getOrder ? this.$store.getters.getOrder : {},
      orderCartItems: [],
      serviceCharge: this.$store.getters.getOrder.serviceCharge
        ? this.$store.getters.getOrder.serviceCharge
        : 0,
    };
  },
  methods: {
    pushResturant() {
      this.$router.push("/restaurants");
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    toggleDiscount() {
      this.showDiscount = !this.showDiscount;
    },
    toggleDiscount() {
      this.showDiscount = !this.showDiscount;
    },
    toggleShowMealInstruction() {
      this.showMealInstruction = !this.showMealInstruction;
    },
    async useDiscount() {
      if (!this.discountCode) {
        this.error = "Discount code is required";
      } else {
        this.showdiscountPreloader = true;
        let data = {
          discountCode: this.discountCode.split(" ").join(""),
          orderId: this.$store.getters.getOrder.id,
        };
        let url = this.$store.state.base_url + "discount/apply";

        try {
          let token = await this.$store.getters.getToken;
          let res = await this.axios.post(url, data, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });
          this.showdiscountPreloader = false;
          this.Discount = res.data.amountToBeDiscounted;
          this.discountMessage = res.data.discountMessage;
          if (this.Discount == 0) {
            this.error = res.data.discountMessage;
          } else {
            this.toggleDiscount();
          }
        } catch (error) {
          this.discountCode = "";
          this.showdiscountPreloader = false;
          this.error = "Discount code not found";
        }
      }
    },
    addMealInfo() {
      this.$store.dispatch("setMealInstruction", this.mealInstruction);
      this.mealInfoMessage = 'Meal instruction added'
      setTimeout(()=>{
        this.mealInfoMessage = ' '
      },5000)
    },
    emptyDiscountCode() {
      this.Discount = 0;
      this.toggleDiscount();
    },
    async updateDiscount() {
      if (this.discountCode) {
        let data = {
          discountCode: this.discountCode.split(" ").join(""),
          orderId: this.$store.getters.getOrder.id,
        };
        let url = this.$store.state.base_url + "discount/apply";
        let token = await this.$store.getters.getToken;
        let res = await this.axios.post(url, data, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        this.Discount = res.data.amountToBeDiscounted;
      }
    },
    distructOrderItem() {
      let order = this.order.cartDto.foodCartItems;
      order.forEach((item) => {
        let cartItem = {
          foodId: item.foodId,
          foodName: item.foodImage,
          quantity: item.quantity,
          amount: parseInt(item.price),
          foodImage: item.foodImage,
          timeBeltId: this.order.cartDto.timeBeltId,
          expectedDeliveryDate: this.order.cartDto.expectedDeliveryDate,
          restaurantId: this.order.cartDto.restaurantId,
          deliveryAddress: this.order.cartDto.deliveryAddress,
        };
        this.orderCartItems.push(cartItem);
        this.updateOrderIteminCheckout();
      });
    },
    updateOrderItem(data) {
      this.order.cartDto.foodCartItems.map((item) => {
        if (item.foodId == data.id) {
          item.quantity = data.quantity;
          item.price = data.price;
        }
      });
    },
    deleteOrderItem(data) {
      this.order.cartDto.foodCartItems =
        this.order.cartDto.foodCartItems.filter(
          (item) => item.foodId != data.id
        );
      if (this.container.length + this.comboCart.length == 0) {
        this.$store.dispatch("removeOrder");
      }
    },
    updateOrderIteminCheckout() {
      this.$emit("updateOrderCheckout", {
        orderCartItems: this.orderCartItems,
      });
    },
    deleteContainer(data) {
      this.order.cartDto.foodContainerCartItems =
        this.order.cartDto.foodContainerCartItems.filter(
          (item, index) => index != data.position
        );
      if (this.container.length + this.comboCart.length == 0) {
        this.$store.dispatch("removeOrder");
      }
    },
  },
  computed: {
    changeType() {
      this.type = this.showCartBtn ? "cart" : "orderSummary";
      return this.type;
    },
    getUrl() {
      return this.$store.getters.getOrderStoreName;
    },
    getCost() {
      this.$store.dispatch("saveOrdercart", this.order.cartDto);
      return this.global.getCartCost(
        this.global.convertCart(this.order.cartDto)
      );
    },
    getTotalCost() {
      return (this.totalAmount =
        this.shippingCost + this.getCost + this.serviceCharge);
    },
    getServiceCharge() {
      return this.$store.getters.getOrder.serviceCharge;
    },
    amountTopay() {
      if (this.activeuser.walletBalance > this.getTotalCost) {
        return 0;
      } else {
        return this.formatNumber(
          this.getTotalCost - this.activeuser.walletBalance
        );
      }
    },
    container() {
      return this.order.cartDto.foodContainerCartItems
        ? this.order.cartDto.foodContainerCartItems
        : [];
    },
    comboCart() {
      return this.order.cartDto.foodCartItems
        ? this.order.cartDto.foodCartItems.filter((item) => item.quantity != 0)
        : [];
    },
  },
  watch: {
    getNewchanges: function () {
      this.serviceCharge = this.$store.getters.getOrder.serviceCharge
        ? this.$store.getters.getOrder.serviceCharge
        : 0;
      this.updateDiscount();
    },
  },
};
</script>
<style>
.tooltip-comp {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  background-color: #212121;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 300px;
  padding: 5px 10px;
  opacity: 0;
  pointer-events: none;
  word-break: break-word;
  text-align: left;
  border-radius: 2px;
}
.tooltip-comp-arrow {
  position: absolute;
  z-index: 1;
  width: 16px;
  height: 16px;
  overflow: hidden;
}
.tooltip-comp-arrow::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  width: 16px;
  height: 16px;
  background-color: #212121;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.tooltip-comp-content {
  position: relative;
  z-index: 2;
}
.tooltip-comp.hide-arrow-icon .tooltip-comp-arrow {
  display: none;
}
.tooltip-comp.position-bottom .tooltip-comp-arrow {
  margin-left: -8px;
  left: 0;
  top: -15px;
}
.tooltip-comp.position-top .tooltip-comp-arrow {
  margin-left: -8px;
  left: 0;
  bottom: -15px;
}
.tooltip-comp.position-right .tooltip-comp-arrow {
  margin-top: -8px;
  top: 0;
  left: -15px;
}
.tooltip-comp.position-left .tooltip-comp-arrow {
  margin-top: -8px;
  top: 0;
  right: -15px;
}
</style>