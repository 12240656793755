<template>
  <div v-show="!deleteItem" class=" text-sm border-white py-2 border-b-2">
    <div class="grid grid-cols-3">
        <div class="">
            <img :src="data.foodImage" class="h-20 w-20 md:h-24 md:w-24 object-cover rounded" alt="">
        </div> 
        <div class="col-span-2">
            <div>
            <div class="grid grid-cols-2 w-full">
                <p class="text-sm md:text-base font-semibold">{{ data.foodName }}</p>
                <div class="flex-grow">
                    <div v-if="name != 'orderSummary'" class="flex justify-end items-center">
                        <button @click="deleteCart()" class="text-red-600">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
                <div class="grid grid-cols-2  mt-2">
                    <div v-if="name != 'orderSummary'" class="flex space-x-2">
                        <button class="bg-brand text-black px-3" @click="minusQty" >-</button>
                        <p class="border py-1 px-3 flex items-center">{{ Quantity }}</p>
                        <button class="bg-brand text-black px-3 " @click="addQty">+</button>
                    </div>
                    <div v-else >
                       Quantity: {{ Quantity }}                              
                    </div>
                    <div class="w-full">
                        <div v-if="QuantityPrice" :class="{'justify-start': name == 'orderSummary', 'justify-end': name != 'orderSummary'}"  class="flex flex-grow ">
                            <p class="flex items-center text-sm md:text-base  text-right font-semibold">&#8358;{{ formatNumber(QuantityPrice)}}</p>
                        </div>  
                        <div v-else class="flex flex-grow justify-end">
                            <p :class="{'text-right': name == 'orderSummary' }" class="flex items-center text-sm  font-semibold">&#8358;{{formatNumber(Amount)}}</p>
                        </div> 
                    </div>
                </div> 
            </div>    
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name:['FoodCart'],
    props:['data','name'],
    emits:['updateCombo','deleteOrderItem'],
    data(){
        return{
            Quantity: this.data.quantity,
            Amount:parseInt(this.data.price)/this.data.quantity,
            QuantityPrice: this.data.price,
            deleteItem:false,
        }
    },
    methods:{
        addQty(){
            this.Quantity++
            this.QuantityPrice = this.Quantity * this.Amount
            this.updateCart(this.Quantity);

        },
        minusQty(){            
            if (this.Quantity > 1 ) {
                this.Quantity--
                this.QuantityPrice = this.Quantity * this.Amount
                this.updateCart(this.Quantity);
            } 
        },
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        updateCart(Quantity){   
            this.data.quantity = Quantity
            this.$store.dispatch("setNewChange",true);
            this.$emit('updateCombo',{
                id: this.data.foodId,
                quantity:Quantity,
                price:this.QuantityPrice
            })
        },
        deleteCart(){
            this.$store.dispatch("setNewChange",true);
            this.$emit('deleteOrderItem',{
                id: this.data.foodId
            })
            this.deleteItem = true;
        }
    },
   
    
}
</script>

<style>

</style>