<template>
    <div v-show="!deleteItem" class=" text-sm border-white py-2 border-b-2">
        <div class="grid grid-cols-3">
            <div v-if="name != 'orderSummary'">
                <img :src="container.foodContainerImage"  @click="toggleModifyCart"  class="h-20 w-20 md:h-24 md:w-24 object-cover cursor-pointer hover:bg-brand rounded" alt="">
            </div> 
            <div v-else>
                <img :src="container.foodContainerImage" class="h-20 w-20 md:h-24 md:w-24 object-cover rounded" alt="">
            </div>
            <div class="col-span-2">
                <div>
                    <div class="grid grid-cols-2 w-full">
                        <p class="text-sm md:text-base font-semibold">Container {{ position + 1 }}</p>
                        <div class="flex-grow">
                            <div v-if="name != 'orderSummary'" class="flex space-x-1 justify-end items-center">
                                <button @click="toggleModifyCart" class="text-green-600">
                                    <img src="@/assets/svg/food-container.svg" alt="" class="h-5 w-5">
                                </button>
                                <button @click="deleteContainer()" class="text-red-600">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="text-xs mb-3 text-ellipsis">
                        Items: <span v-for="item in container.foodCartItems" :key="item">{{ item.foodName }}({{ item.quantity }})</span>
                    </div>
                    <div class="grid grid-cols-2  mt-2">
                        <div v-if="name != 'orderSummary'" class="flex space-x-2">
                            <button class="bg-brand text-black px-3" @click="minusQty" >-</button>
                            <p class="border py-1 px-3 flex items-center">{{ Quantity }}</p>
                            <button class="bg-brand text-black px-3 " @click="addQty">+</button>
                        </div>
                        <div v-else >
                           Quantity: {{ Quantity }}                              
                        </div>
                        <div class="w-full">
                            <div v-if="QuantityPrice" :class="{'justify-start': name == 'orderSummary', 'justify-end': name != 'orderSummary'}"  class="flex flex-grow ">
                                <p class="flex items-center text-sm md:text-base  text-right font-semibold">&#8358; {{ getTotal() }}</p>
                            </div>  
                            <div v-else class="flex flex-grow justify-end">
                                <p :class="{'text-right': name == 'orderSummary' }" class="flex items-center text-sm  font-semibold">&#8358; {{ this.global.formatNumber(container.unitPrice * Quantity) }}</p>
                            </div> 
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
    <Modal v-if="showModifyContainer" :show=true width="lg:w-500 w-full">
        <div class="flex justify-between items-center top-0 bg-white pb-2">
            <p class="text-xl font-semibold">Modify your container</p>
            <div @click="toggleModifyCart">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
        <div v-if="error" class="text-sm text-red-500">{{ error }}</div>
        <div v-if="container.foodCartItems.length" class="bg-gray-100 p-4 flex flex-col gap-4 rounded-md my-3">
            <div v-for="meal in container.foodCartItems" :key="meal" class="text-sm">
                <div class="grid grid-cols-2 lg:grid-cols-3">
                    <div>{{ meal.foodName }}</div>
                    <div class="text-right lg:text-center">&#8358;{{ meal.price/meal.quantity }}/portion</div>
                    <div class="hidden lg:flex gap-6 justify-center items-center">
                        <div class="flex items-center gap-2">
                            <button @click="substractMeal(meal.foodId)" class="bg-gray-300 h-6 w-6 rounded-md flex justify-center items-center">
                                -
                            </button>
                            <div class="h-6 w-6 rounded-md text-xs flex justify-center items-center">
                                {{ meal.quantity }}
                            </div>
                            <button @click="addMeal(meal.foodId)" class="bg-brand h-6 w-6 rounded-md flex justify-center items-center">
                                +
                            </button >
                        </div>
                        <div class="flex items-center">
                            <button @click="removeMeal(meal.foodId)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="lg:hidden flex gap-3 justify-start items-center mt-4">
                    <div class="flex items-center gap-2">
                        <button @click="substractMeal(meal.foodId)" class="bg-gray-300 h-6 w-6 rounded-md flex justify-center items-center">
                            -
                        </button>
                        <div class="h-6 w-6 rounded-md text-xs flex justify-center items-center">
                            {{ meal.quantity }}
                        </div>
                        <button @click="addMeal(meal.foodId)" class="bg-brand h-6 w-6 rounded-md flex justify-center items-center">
                            +
                        </button >
                    </div>
                    <div class="flex items-center">
                        <button @click="removeMeal(meal.foodId)">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>

            </div>
        </div>
        <div class="mt-4 flex-grow flex space-x-2 justify-end">
            <div>
                <button @click="toggleModifyCart"  type="submit" class="bg-gray-200 text-black px-3 text-sm py-2 font-semibold rounded">    
                    Close
                </button>
            </div>
            <div>
                <button @click="toggleModifyCart" type="submit" class="bg-brand text-black px-3 text-sm py-2 font-semibold rounded">    
                   Update Container
                </button>
            </div>
        </div>
    </Modal>
    </template>
    <script>
    import Modal from "@/components/Modal.vue";
    export default{
        name:"ContainerCart",
        props:['container','position','name'],
        emits:['deleteContainer'],
        components:{
            Modal
        },
        data(){
            return {
               Quantity: this.container.quantity,
               deleteItem:false,
               amount:0,
               showModifyContainer:false,
               error:null
            }
        },
        computed:{
            QuantityPrice(){
                return this.container.totalPrice
            }
        },
        methods:{
            calculateContainerPrice(){
                let cost = 0;
                this.container.foodCartItems.forEach((item)=>{
                    cost = cost + (item.quantity * item.amount)
                })
            },
            getTotal(){
                let cost = 0;
                this.container.foodCartItems.forEach((item)=>{
                    cost = cost + item.price
                })

                cost = (cost + this.container.containerPrice) * this.container.quantity

                return this.global.formatNumber(cost);
            },
            addQty(){
                this.Quantity++
                this.container.quantity++
                this.updateContainer();
            },
            minusQty(){
                if (this.Quantity > 1 ) {
                    this.Quantity--
                    this.container.quantity--
                    this.updateContainer()
                }
    
            },
            updateContainer(){
                this.$store.dispatch("setNewChange",true);
            },
            deleteContainer(){
                this.$store.dispatch("setNewChange",true);
                this.$emit('deleteContainer',{
                    position: this.position
                })
                this.deleteItem = true;
            },
            toggleModifyCart(){
                this.showModifyContainer = !this.showModifyContainer
            },
            addMeal(id){
                this.container.foodCartItems.map(item => {
                    if(item.foodId == id){
                        let unitPrice = item.price/item.quantity;
                        item.price = unitPrice * (item.quantity + 1)
                        item.quantity ++;
                    }
                });
                this.$store.dispatch("setNewChange",true);
            },
            substractMeal(id){
                this.container.foodCartItems.map(item => {
                    if(item.foodId == id){
                        let unitPrice = item.price / item.quantity;
                        if(item.quantity > 1){
                            item.price = unitPrice * (item.quantity - 1)
                            item.quantity --
                        }

                    }
                });
                tthis.$store.dispatch("setNewChange",true);
            },
            removeMeal(id){
                let remainingCartitems = this.container.foodCartItems.filter(item => item.foodId != id);

                if(remainingCartitems.length){
                    this.$store.dispatch("setNewChange",true);
                    return this.container.foodCartItems = this.container.foodCartItems.filter(item => item.foodId != id);
                }

                this.error = 'A container cannot be empty'

                setTimeout(()=>{
                    this.error = null;
                },2000)

            }
    
        }
    }
</script>