<template>
  <Header />
  <div class="bg-gray-100">
    <Title name="Checkout" />
    <BreadCrumb name="Checkout" />
    <div
      v-if="orderState && paymentConfirmed == false"
      class="mx-3 lg:mx-0 lg:ml-16 py-16"
    >
      <div class="lg:grid lg:grid-cols-3 lg:gap-4">
        <div class="col-span-2 mb-14 lg:mr-16">
          <div class="bg-white p-5 rounded shadow-md pb-6">
            <h1 class="text-2xl font-semibold">Enter your delivery details</h1>
            <div>
              <div class="mt-8 flex space-x-3">
                <div class="flex items-center">
                  <input
                    type="checkbox"
                    class="h-4 w-4"
                    @change="populateUser"
                    v-model="personal"
                  />
                </div>
                <p class="font-semibold flex items-center">
                  Use personal details
                </p>
              </div>
            </div>
            <form @submit.prevent="CheckForm">
              <div class="mt-3">
                <label for="email" class="text-sm block">Full name</label>
                <input
                  type="text"
                  ref="name"
                  name="name"
                  :disabled="personal"
                  v-model="name"
                  placeholder="Enter your full name"
                  class="input"
                />
                <span
                  v-show="error.name"
                  class="text-red-600 font-semibold text-sm"
                  >{{ error.name }}</span
                >
              </div>
              <div class="mt-3">
                <label for="email" class="text-sm block">Phone Number</label>
                <input
                  maxlength="11"
                  ref="phoneNumber"
                  :disabled="personal"
                  type="tel"
                  name="phone"
                  placeholder="Enter your phone number"
                  v-model="phone"
                  class="input"
                />
                <span
                  v-show="error.phone"
                  class="text-red-600 font-semibold text-sm"
                  >{{ error.phone }}</span
                >
              </div>
              <div class="mt-3">
                <label for="email" class="text-sm block"
                  >Additional Information</label
                >
                <textarea
                  name="additionInfo"
                  class="input"
                  id=""
                  placeholder="Eg Bustop, Landmark, Additional phone number"
                  cols="10"
                  rows="2"
                  v-model="additionInfo"
                ></textarea>
              </div>
            </form>
            <div class="mt-4 text-sm">
              <span class="font-semibold text-sm">Delivering to</span>
              {{ deliveryAddress }}
            </div>
          </div>
        </div>
        <div ref="pills">
          <div class="bg-white rounded p-4 shadow-md mb-5">
            <OrderSurmmary
              @updateOrderCheckout="updateOrderIteminCheckout"
              :getNewchanges="getNewchanges"
              :showCartBtn="showCartBtn"
              :activeuser="activeuser"
              :shippingCost="shippingCost"
              :showCart="showCart"
            />
            <div class="flex flex-grow space-x-3">
              <button
                @click="CheckForm"
                :disabled="getNewchanges"
                :class="{
                  'bg-brand': getNewchanges == false,
                  'bg-gray-200': getNewchanges == true,
                }"
                class="shadow-lg w-2/4 flex justify-center text-black font-semibold rounded-md my-4 px-4 py-4"
              >
                <Preloader v-if="showPreloader" />
                <span v-else>Pay Now</span>
              </button>
              <button
                v-if="getNewchanges"
                @click="sendCartOrder"
                class="py-3 my-4 w-2/4 bg-brand rounded-md shadow-lg flex justify-center items-center"
              >
                <Preloader v-if="showUpdatePreloader" />
                <span v-else>Update Cart</span>
              </button>
              <button
                v-else
                @click="updateCart"
                class="py-3 my-4 rounded-md w-2/4 bg-gray-200 shadow-lg"
              >
                Modify Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="col-span-2 pb-14 mt-5 lg:mx-16">
      <div
        class="bg-white shadow-md h-400 flex mx-3 lg:mx-0 justify-center items-center rounded"
      >
        <div
          v-if="paymentConfirmed"
          class="fixed top-0 w-full z-30 flex justify-center items-center bg-white h-screen"
        >
          <div>
            <h5
              class="text-base text-center text-gray-700 font-semibold mt-4 animate__animated animate__slow animate__infinite animate__flash"
            >
              Confirming your payment ...
            </h5>
            <p class="text-xs text-center text-gray-700 font-semibold mt-4">
              Please do not refresh or leave this page while confirming payment
            </p>
          </div>
        </div>
        <div v-else class="">
          <div class="flex justify-center">
            <img src="@/assets/svg/order.svg" class="h-36 w-36" alt="" />
          </div>
          <h5 class="text-xl text-gray-700 font-semibold my-4 m">
            Your cart is empty
          </h5>
          <router-link
            :to="{ name: 'ResturantsPage', params : { publicUrl: this.publicUrl} }"
            class="text-center flex justify-center font-semibold rounded text-sm bg-brand p-3 mt-2"
            >Place an order</router-link
          >
        </div>
      </div>
    </div>
  </div>
  <Footer />
  <Modal v-if="showUpdateModal" :show="true">
    <h1 class="text-xl font-semibold">Save Changes?</h1>
    <p class="my-4 text-sm">You have modified you cart update your order</p>
    <div class="flex space-x-4 justify-end">
      <button
        @click="closeUpdateModal"
        class="bg-gray-200 text-sm py-2 px-3 rounded-sm"
      >
        No
      </button>
      <button
        @click="sendCartOrder"
        class="bg-brand text-sm py-2 px-3 rounded-sm"
      >
        Update
      </button>
    </div>
  </Modal>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer";
import Modal from "@/components/Modal.vue";
import Title from "@/components/Title.vue";
import BreadCrumb from "@/components/BreadCrumb.vue";
import OrderSurmmary from "@/components/OrderSurmmary.vue";
import Preloader from "@/components/Preloader.vue";
import store from "@/store";
import "animate.css";

export default {
  name: "Checkout",
  components: {
    Header,
    Footer,
    Title,
    OrderSurmmary,
    BreadCrumb,
    Preloader,
    Modal,
  },
  data() {
    return {
      showCount: this.$store.getters.getcountCart,
      email: "",
      name: "",
      phone: "",
      showUpdateModal: false,
      showUpdatePreloader: false,
      Address: "",
      anyChanges: false,
      showCartBtn: false,
      showCart: [],
      personal: true,
      shippingCost: 0,
      additionInfo: "",
      showPreloader: false,
      paymentConfirmed: false,
      timebeltId: 0,
      paykey: process.env.VUE_APP_PAYSTACK_KEY,
      publicUrl: process.env.VUE_APP_DEFAULT_STORE,
      cart: [],
      id: 0,
      discountCode: "",
      activeuser: "",
      additionMealInfo: "",
      error: {
        email: "",
        name: "",
        phone: "",
        discountCode: "",
      },
      orderItems: [],
    };
  },
  computed: {
    orderState() {
      return this.$store.getters.getOrder;
    },
    deliveryAddress() {
      return this.orderState.address;
    },
    getuserState() {
      return (this.activeuser = this.$store.state.userProfile);
    },
    getNewchanges() {
      return this.$store.getters.getNewChange;
    },
  },
  methods: {
    GetUserProfile() {
      this.activeuser = this.$store.state.userProfile;
      this.populateUser();
    },
    async sendCartOrder() {
      this.showUpdatePreloader = true;
      let url =
        this.$store.state.base_url +
        `order/modify/${this.$store.getters.getOrder.id}`;
      let orderItems = this.$store.state.orderCart;
      orderItems.foodCartItems = orderItems.foodCartItems || [];
      orderItems.foodContainerCartItems =
        orderItems.foodContainerCartItems || [];
      let data = {
        cartDto: this.$store.state.orderCart,
      };
      try {
        let token = await this.$store.getters.getToken;
        let res = await this.axios.post(url, data, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        this.showCartBtn =
          this.showUpdatePreloader =
          this.showUpdateModal =
            false;
        this.$store.dispatch("setNewChange", false);
        this.$store.dispatch("addOrder", JSON.stringify(res.data));
      } catch (error) {
        this.showUpdateModal = this.showUpdatePreloader = false;
      }
    },
    updateCart() {
      this.showCartBtn = true;
      let scrollToPills = this.$refs.pills.getBoundingClientRect().top;
      window.scrollTo(0, scrollToPills);
    },
    closeUpdateModal() {
      this.showUpdateModal = false;
    },
    populateUser() {
      if (this.personal && this.activeuser) {
        this.name = `${this.activeuser.firstName} ${this.activeuser.lastName}`;
        this.phone = `${this.activeuser.phoneNumber}`;
        this.email = `${this.activeuser.email}`;
      } else {
        (this.name = this.phone = ""),
          (this.$refs.name.disabled = this.$refs.phoneNumber.disabled = false);
      }
    },
    async getShippingCost() {
      this.shippingCost = this.$store.getters.getOrder.shippingCost
        ? this.$store.getters.getOrder.shippingCost
        : 0;
    },

    async CheckForm() {
      let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      let regexPhone = /^[0]\d{10}$/gm;
      let loggedUser = this.$store.getters.getUser;
      let token = await this.$store.getters.getToken;
      //email
      this.error.email = !this.email.match(regexEmail)
        ? "Invaild email address entered"
        : "";
      this.error.name = !this.name ? "Enter your fullname" : "";
      this.error.phone = !regexPhone.test(this.phone)
        ? "invaild phone number entered"
        : "";
      if (!this.error.name && !this.error.email && !this.error.phone) {
        this.showPreloader = true;
        let data = {
          additionalInformation: this.additionInfo,
          deliveryName: this.name,
          deliveryPhone: this.phone,
          orderId: this.$store.getters.getOrder.id,
          additionMealInfo: this.$store.getters.getMealInstruction,
        };
        try {
          let url = this.$store.state.base_url + "payment/process";
          let res = await this.axios.post(url, data, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });
          let orderID = res.data.orderReferenceNumber;
          let paymentRef = res.data.paymentReferenceNumber;
          let interID = res.data.orderId;
          let payurl = await this.$store.state.base_url + `payment/pay/${interID}`;
          await this.axios.post(payurl, null, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });
          console.log('step1',payurl)
          if (res.data.amountToPayWithPaymentGateway > 0) {
            let totalPaystackCost = res.data.amountToPayWithPaymentGateway;
            console.log(totalPaystackCost,'totalPaystackCost')
            console.log('step2')
            this.payWithPaystack(
              this.email,
              totalPaystackCost,
              orderID,
              res,
              paymentRef
            );
            this.showPreloader = false;
          } else {
            await this.payWithWallet(res);
          }
        } catch (error) {
          this.showPreloader = false;
        }
      }
    },
    async payWithWallet(response) {
      this.paymentConfirmed = true;
      let token = await this.$store.getters.getToken;
      let url = this.$store.state.base_url + `order/${response.data.orderId}`;
      let res = await this.axios.get(url, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      this.confirmed(res);
    },
    async checkoutonclose(paymentRef, self) {
      let url = self.$store.state.base_url + "payment/paystack/on-close";
      let token = await self.$store.getters.getToken;
      await self.axios.post(url, null, {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          paymentReference: paymentRef,
        },
      });
    },
    payWithPaystack(email, amount, orderID, resbackend, paymentRef) {
      var self = this; // assign context to self variable
      const paystack = require("../paystack/index.js");
      let paykey = this.paykey;
      let paystackRes = [];
      console.log('paystack function')
      console.log(PaystackPop,'PaystackPop')
      let handler = PaystackPop.setup({
        key: paykey,
        email: email,
        amount: amount * 100,
        ref: paymentRef,
        onClose: function () {
          self.checkoutonclose(paymentRef, self);
          self.$store.dispatch("addCountcart", 0);
          self.$store.dispatch("removeOrder");
        },
        callback: async function (response) {
          paystackRes = response;
          self.$store.dispatch("emptyCart");
          self.paymentConfirmed = true;
          let token = await self.$store.getters.getToken;
          let url = store.state.base_url + `order/${resbackend.data.orderId}`;
          let res = await self.axios.get(url, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });
          self.confirmed(res);
        },
      });
      handler.openIframe();
    },
    async confirmed(res) {
      this.$store.dispatch("addOrder", JSON.stringify(res));
      let loggedUser = this.$store.getters.getUser;
      let token = await this.$store.getters.getToken;
      let url = this.$store.state.base_url + "user-profile";
      try {
        let res = await this.axios.get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        this.showPreloader = false;
        this.$store.dispatch("addUserProfile", res.data);
        this.$router.push("/payment-information");
      } catch (error) {
        this.showPreloader = false;
      }
    },
    updateOrderIteminCheckout(data) {
      this.orderItems = data.orderCartItems;
    },
  },
  beforeMount() {
    if (this.showCart) {
      this.getShippingCost();
    }
  },
  beforeUnmount() {
    if (this.anyChanges) {
      this.showUpdateModal = true;
    }
  },
  mounted() {
    this.GetUserProfile();
    console.log(this.$store.getters.getMealInstruction)
  },
};
</script>

<style>
:root {
  --loader-width: 20px;
  --loader-height: 20px;
  --loader-color-primary: #000000;
  --loader-color-secondary: #eee;
  --line-width: 1px;
  --animation-duration: 2s;
  --loader-initial-scale: 0.1;
}
</style>